import { render, staticRenderFns } from "./InsRecommend.vue?vue&type=template&id=5fc21a91&scoped=true&"
import script from "./InsRecommend.vue?vue&type=script&lang=ts&"
export * from "./InsRecommend.vue?vue&type=script&lang=ts&"
import style0 from "./InsRecommend.vue?vue&type=style&index=0&id=5fc21a91&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/_vue-loader@15.8.3@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5fc21a91",
  null
  
)

export default component.exports
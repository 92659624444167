














































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import Comments, { CommentImages } from '@/model/Comments';
import Cookie from 'js-cookie';
@Component
export default class InsAddComments extends Vue {
    private commentsList: Comments[] = [];
    // private fileList: any[] = [];
    private uploadUrl: string = '';
    private files: any[][] = [];
    private dialogImageUrl: string = '';
    private dialogVisible: boolean = false;
    created () {
      this.$Api.comments.getOrderComments(this.orderId).then((result) => {
        this.commentsList = result.Comments;
        this.files = this.commentsList.map((element) => {
          return element.CommentImages.map((e) => {
            return { url: e.BigImagePath };
          });
        });
      });
    }
    uploadSucceed (index) {
      return (response, file, fileList) => {
        response.ReturnValue.forEach((element) => {
          this.commentsList[index].CommentImages.push(new CommentImages(element.Name));
        });
      };
    }
    remove (index) {
      return (file, fileList) => {
        this.commentsList[index].CommentImages.forEach((element, i) => {
          if (file.url === element.BigImagePath) this.commentsList[index].CommentImages[i].IsDelete = true;
        });
      };
    }
    async beforeAvatarUpload (file) {
      this.uploadUrl = await this.$Api.comments.uploadImage();
      return true;
    }
    get orderId () {
      return this.$route.params.id;
    }
    get token () {
      return Cookie.get('access_token');
    }
    saveAll () {
      // console.log(this.commentsList);
      this.$Api.comments.saveComments(this.commentsList).then(() => { this.$message(this.$t('Message.SucceedInOperating') as string); });
    }

    save (index) {
      this.$Api.comments.saveComments([this.commentsList[index]]).then(() => {
        this.$message(this.$t('Message.SucceedInOperating') as string);
        setTimeout(() => {
          this.$router.go(-1);
        }, 1000);
      });
    }
}

















































































import { Component, Prop, Vue } from 'vue-property-decorator';
@Component({
  components: {
    InsBanner: () => import('@/components/base/mobile/InsBanner.vue')
  }
})
export default class InsMyCoupon extends Vue {
  currentPage: number = 1;
  pagesize: number = 12;
  SearchInput: any = '';
  value: any = '';
  NewArarry: any[] = [];
  coupons:any[]=[];
  groundColor:string='';
  // 下拉框筛选可用和已用
  dataFilter (val) {
    var that = this;
    that.value = val;
    that.NewArarry = that.coupons.filter(item => {
      return (item.IsExpiry === that.value && (item.IsUsed === that.value || item.IsUsed === !that.value));
    });
  }

  // 页面加载完成后，把数组的值付给新的数组
  async created () {
    var that = this;
    this.getAllCoupon().then(() => {
      console.log(that.coupons, '输出数组');
      this.NewArarry = that.coupons.filter(item => {
        return that.NewArarry;
      });
    });
  }

  // 改变当前页
  currentChange (currentPage) {
    this.currentPage = currentPage;
  }

  // 点击上一页和下一页的时事件
  handleSizeChange (psize) {
    this.pagesize = psize;
  }

  // 计算数组的长度，用于分页和显示总数
  get couponsLength () {
    return this.coupons.length;
  }

  // 计算可用优惠券数量;
  get NoUseLength () {
    return this.coupons.filter(item => {
      return item.IsUsed === false && item.IsExpiry === false;
    }).length;
  }

  // 计算已用优惠券数量;
  get UsedLength () {
    return this.coupons.filter(item => {
      return item.IsUsed === true || item.IsExpiry === true;
    }).length;
  }

  getAllCoupon () {
    let that = this;
    return this.$Api.promotion.getAllCoupon({ status: 3 }).then((result) => {
      if (result) {
        that.coupons = result.ReturnValue.Data;
        that.coupons.forEach(function (i) {
          var EffectiveDate = new Date(i.EffectiveDate.replace(/-/g, '/'));
          var ExpiryDate = new Date(i.ExpiryDate.replace(/-/g, '/'));
          i.EffectiveDate = EffectiveDate.getDate() + '/' + (EffectiveDate.getMonth() + 1) + '/' + EffectiveDate.getFullYear();
          i.ExpiryDate = ExpiryDate.getDate() + '/' + (ExpiryDate.getMonth() + 1) + '/' + ExpiryDate.getFullYear();
        });
        console.log(that.coupons, '获取所有优惠券的列表');
      } else {
        console.log('没有数据');
      }
    }).catch((error) => {
      console.log(error);
    });
  }
  form: any = {
    region: ''
  };
}

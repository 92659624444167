











import { Component, Prop, Vue } from 'vue-property-decorator';
@Component({
  components: {
    // PkcmsBanner: () => import('@/components/edBusiness/mobile/cms/PkcmsBanner.vue')
  }
})
export default class Form extends Vue {
        content:any='';
        IsPay:boolean= false;
        IsMobile: boolean = true;
        getForm () {
          this.$Api.regAndPay.getHtml(this.$route.params.id, this.currentlang, this.IsMobile).then(result => {
            this.content = result.HtmlString;
            this.IsPay = result.IsPay;
            this.$nextTick(() => {
              dispatchEvent(new Event('rnpFinshed'));
            });
          });
        }
        get currentlang () {
          return this.$Storage.get('locale');
        }
        created () {
          this.getForm();
        }
        mounted () {
          window['regAndPay'] = this.$Api.regAndPay;
          window['router'] = this.$router;

          window['Elalert'] = this.$alert;

          // eslint-disable-next-line no-unused-expressions
          window['save'];
          // eslint-disable-next-line no-unused-expressions
          window['checkForm'];
          // eslint-disable-next-line no-unused-expressions
          window['preview'];
          // eslint-disable-next-line no-unused-expressions
        }
}























import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
@Component({})
export default class InsCatDetail extends Vue {
  cateList: any[] = [];
  content:any[]=[];
  categoryIndex:number=0;
  private ImgList: string[] = [];
  private ispic:boolean=false;
  get id () {
    return this.$route.params.id;
  }
  getCate () {
    this.$Api.cms.getContentsByCatId(this.id, 1, 12).then(result => {
      this.cateList = result.Data;
    });
  }
  showContent (id, index) {
    let contentId = id;
    this.$Api.cms.getContentByDevice({ ContentId: contentId, IsMobile: true }).then(result => {
      this.content = result.CMS;
      this.ImgList = result.CMS.Cover;
      this.categoryIndex = index;
    });
  }
  created () {
    this.getCate();
    this.showContent(20311, 0);
  }
  hidden () {
    this.$HiddenLayer();
  }
  @Watch('$route', { deep: true })
  onIdChange () {
    this.$Api.cms.getContentsByCatId(this.id, 1, 12).then(result => {
      this.content = result.Data;
    });
    this.showContent(20311, 0);
  }
}























import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
@Component({})
export default class InsCatDetail extends Vue {
  cateList: any[] = [];
  content:any[]=[];
  categoryIndex:number=0;
  cateName:string='';
  private ImgList: string[] = [];
  private ispic:boolean=false;
  get id () {
    return this.$route.params.id;
  }
  getCate () {
    this.$Api.cms.getContentsByCatId(this.id, 1, 12).then(result => {
      this.cateList = result.Data;
      result.Data.forEach((item) => {
        this.ImgList = item.Cover;
      });
    });
  }
  getCateDetail () {
    var _this = this;
    _this.$Api.cms.getCatDetail(40115).then(result => {
      _this.cateName = result.ReturnValue.Name;
      document.title = _this.cateName as string;
    });
  }
  showContent (id, index) {
    let contentId = id;
    this.$Api.cms.getContentByDevice({ ContentId: contentId, IsMobile: false }).then(result => {
      this.content = result.CMS;
      this.categoryIndex = index;
    });
  }
  created () {
    var _this = this;
    _this.getCate();
    _this.showContent(20311, 0);
    _this.getCateDetail();
  }
  hidden () {
    this.$HiddenLayer();
  }
  @Watch('$route', { deep: true })
  onIdChange () {
    this.$Api.cms.getContentsByCatId(this.id, 1, 12).then(result => {
      this.content = result.Data;
    });
    this.showContent(20311, 0);
  }
}






































import { Vue, Prop, Component, Watch } from 'vue-property-decorator';
@Component
export default class InsConcessions extends Vue {
    private totalAmount:number = 0;
    private total:number = 0;
    private CurrencyCode:string ='';
    currentCode:string='HKD';
    @Prop() private lockConcessions!:boolean;
    created () {
      this.$store.state.shopCart.then((result) => {
        this.totalAmount = result.ShopCart.TotalAmount;
        this.CurrencyCode = result.ShopCart.Currency.Code;
        console.log(result, 'resultresultresultresult1111');
      });
    }
    LoadData () {
      this.$Api.shoppingCart.LoadData().then((result) => {
        this.currentCode = result.Currency.Code;
      });
    }
    mounted () {
      this.LoadData();
    }
    get express () {
      return this.$store.state.pickUpExpress ? this.$store.state.expressPoint : this.$store.state.express;
    }
}

import { render, staticRenderFns } from "./InsProductDetail.vue?vue&type=template&id=85add488&scoped=true&"
import script from "./InsProductDetail.vue?vue&type=script&lang=ts&"
export * from "./InsProductDetail.vue?vue&type=script&lang=ts&"
import style0 from "./InsProductDetail.vue?vue&type=style&index=0&id=85add488&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.8.3@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "85add488",
  null
  
)

export default component.exports

























































import { Component, Prop, Vue } from 'vue-property-decorator';
import Commodity from '@/components/business/pc/account/InsCommodity.vue';
import Fare from '@/components/business/pc/account/InsFare2.vue';
import Subtotal from '@/components/business/pc/account/InsSubTotal.vue';
import Payment from '@/components/business/pc/account/InsPayment.vue';
import Addresspopup from '@/components/business/pc/account/InsAddressPopUp.vue';
import ShopCart from '@/model/ShopCart';
import Express from '@/model/express';
import Address from '@/model/address';
import PaymentM from '@/model/payment';
import Order, { CreateOrder } from '@/model/order';
import { Country } from '@/model/country';
import PickupAddress from '@/model/pickupAddress';
@Component({
  components: {
    SildeBanner: () => import('@/components/base/pc/InsBanner.vue'),
    Commodity,
    Fare,
    Subtotal,
    Payment,
    Addresspopup
  }
})
export default class InsCheckout extends Vue {
  data () {
    return {
      prodcutSrc: require('@/assets/Images/270_b.jpg'),
      step: 1,
      totalAmount: 0,
      itemsAmount: 0,
      ItemsTaxAmount: 0,
      Currency: {},
      MaxQty: 20,
      cartItems: [],
      currentCode: '',
      deliveryType_s: 'D',
      items: [
        {
          Id: 1,
          AttrTypeName1: '尺码',
          AttrName1: '中',
          AttrTypeName2: '颜色',
          AttrName2: '黑色',
          AttrTypeName3: '大小',
          AttrName3: '小码',
          Qty: 2,
          Product: {
            Img_M: require('@/assets/Images/270_b.jpg'),
            Name: '安踏T',
            Code: '12346',
            SalePrice: 245,
            Sku: '/product/list',
            Currency: {
              Code: 'HKD',
              Desc: null,
              Id: 1,
              Name: 'HKD'
            }
          }
        }
      ],
      orderSure: false,
      selectAddress: '',
      selectPickupAddress: '',
      selectExpressOne: 0,
      countryList: [
        {
          Name: '中國香港'
        }
      ],
      provinceList: [
        {
          Name: '中國香港'
        }
      ],
      expressList: [
        {
          currentCode: 'HKD',
          CountryCode: null,
          Discount: 50,
          DiscountPrice: 0,
          ExpressCompanyId: '1046',
          ExpressCompanyName: '郵費（香港）',
          ExpressPointId: '0',
          ExpressPointList: null,
          Id: '1046',
          IsExpressPoint: false,
          IsSelfDefineDeliveryDate: true,
          Price: 50,
          ServiceType: null,
          value: '选项1',
          label: {
            ExpressCompanyName: '郵費（香港）',
            currentCode: 'HKD',
            ExpressPointId: '0'
          }
        }
      ]
    };
  }
  private payments:PaymentM[] = [new PaymentM()];
  private Country:Country[] = [];
  private express:Express = new Express();
  private payment!:PaymentM;
  private checkouting = true;
  Hyperlink () {
    window.location.href = '/account/shoppingcart';
  }
  pay () {
    let order;
    let profile = this.$store.state.memberInfo;
    if (this.$store.state.DeliveryType === 'D') order = new CreateOrder(this.$store.state.pickUpExpress ? -1 : this.$store.state.selectAddress.DeliveryId, this.$store.state.express.Id, this.payment.Id, 'D', '', '', profile.Mobile, profile.FirstName + profile.LastName, '', this.$store.state.expressPoint.Id);
    else if (this.$store.state.DeliveryType === 'P') order = new CreateOrder(this.$store.state.pickupAddress.Id, '', this.payment.Id, 'P', this.$store.state.pickupAddress.PickupDate, this.$store.state.pickupAddress.PickupTime);
    this.$Api.order.createOrder(
      order
    ).then((result) => {
      if (result.Message.Succeeded) {
        this.$store.dispatch('setDeliveryType', 'D');
        this.$store.dispatch('setExpress', new Express());
        this.$store.dispatch('setPickupAddress', new PickupAddress());
        this.$router.push({ name: 'completeCheckout', params: { id: result.Message.ReturnValue } });
      } else { Vue.prototype.$Confirm('error', result.Message.Message); }
    });
  }
  checkOut () {
    if (this.payment !== undefined && (this.$store.state.DeliveryType === 'P' || this.$store.state.express.Id !== '')) {
      this.checkouting = false;
      this.$Api.paymentApi.savePayMethod(this.payment.Id).then((result) => { console.log(result); });
    } else {
      Vue.prototype.$Confirm('请填写完整信息', '快递方式或支付方式尚未选择！');
    };
  }
  created () {
    this.$store.dispatch('setShopCart', this.$Api.shoppingCart.getShoppingCart());
    this.$store.dispatch('setAddress', this.$Api.checkout.getAddress2());
    let pm = this.$Api.checkout.getPaymentMethod().then(result => {
      this.payments = result.Payment;
    });
    Promise.all([this.$store.state.shopCart, this.$store.state.address, pm]).then(() => {
      this.$HiddenLayer();
    });
  }
}

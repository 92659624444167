














import { Vue, Prop, Component, Watch } from 'vue-property-decorator';
import TabInfo from '@/model/Tab';
@Component
export default class InsTab extends Vue {
  // data
  private Current: number = 0;
  //   props
  @Prop() private readonly tabs!: TabInfo;
  @Prop() private readonly headerStyla!: string;
  //   method
  choseTab (i: number) {
    // if (this.tabs[i].action) {
    //   this.$emit(this.tabs[i].action[i]);
    // } else {
    this.Current = i;
    // }
  }
}

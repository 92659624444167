import { render, staticRenderFns } from "./InsOrderDetail.vue?vue&type=template&id=7e39e6d7&scoped=true&"
import script from "./InsOrderDetail.vue?vue&type=script&lang=ts&"
export * from "./InsOrderDetail.vue?vue&type=script&lang=ts&"
import style0 from "./InsOrderDetail.vue?vue&type=style&index=0&id=7e39e6d7&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/_vue-loader@15.8.3@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7e39e6d7",
  null
  
)

export default component.exports
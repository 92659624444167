






































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import Payment from '@/model/payment';
@Component
export default class InsPayment extends Vue {
  data () {
    return {
      orderSure: false,
      currentCode: ''
      // totalAmount: 0,
      // paymentMethods: [
      //   {
      //     Img: require('@/assets/Images/paypal-icon.png'),
      //     Name: 'Paypal'
      //   }
      // ],
    };
  }
  @Prop() private readonly paymentMethods!:Payment;
  private totalAmount:number = 0;
  @Prop() private readonly checkouting!:boolean;
  private radio: Payment = new Payment();
  private promotionCode :string = '';
  @Watch('radio')
  onRadioChange (o, n) {
    this.$emit('paymentChange', this.radio);
  }
  created () {
    this.$store.state.shopCart.then((result) => {
      this.totalAmount = result.ShopCart.TotalAmount;
    });
  }
  searchCoupon () {
    this.$Api.order.getPromotionCodeFrontView(this.promotionCode).then((result) => {
      this.$store.dispatch('setDiscount', result.PromotionDiscount);
    }).catch((error) => {
      this.$message(error);
    });
  }
  get discount () {
    return this.$store.state.discount;
  }
}

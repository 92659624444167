import { render, staticRenderFns } from "./InsPoints.vue?vue&type=template&id=80ed1fc6&scoped=true&"
import script from "./InsPoints.vue?vue&type=script&lang=ts&"
export * from "./InsPoints.vue?vue&type=script&lang=ts&"
import style0 from "./InsPoints.vue?vue&type=style&index=0&id=80ed1fc6&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/_vue-loader@15.8.3@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "80ed1fc6",
  null
  
)

export default component.exports
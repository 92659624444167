


















import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
@Component({})
export default class InsCatDetail extends Vue {
  cateList: any[] = [];
  content:any[]=[];
  cateTitle:string='';
  categoryIndex:number=0;
  private ImgList: string[] = [];
  private ispic:boolean=false;
  get id () {
    return this.$route.params.id;
  }
  getCateImg () {
    this.$Api.cms.GetCategoryByDevice({ CatId: this.id, IsMobile: false }).then(result => {
      this.ImgList = result.ReturnValue.ImagePath;
      this.cateTitle = result.ReturnValue.Name;
    });
  }
  getCate () {
    this.$Api.cms.getContentsByCatId(this.id, 1, 12).then(result => {
      this.cateList = result.Data;
    });
  }
  showContent (val) {
    if (val.Url) {
      window.open(val.Url);
    } else {
      this.$router.push('/cms/content/' + val.Id);
    }
  }
  created () {
    this.getCate();
    this.getCateImg();
    this.$nextTick(() => {
      document.title = this.$t('media') as string;
    });
  }
  hidden () {
    this.$HiddenLayer();
  }
  @Watch('$route', { deep: true })
  onIdChange () {
    this.$Api.cms.GetCategoryByDevice({ CatId: this.id, IsMobile: false }).then(result => {
      this.ImgList = result.ReturnValue.ImagePath;
      this.cateTitle = result.ReturnValue.Name;
    });
  }
}
























import { Vue, Prop, Component, Watch } from 'vue-property-decorator';
import inTab from '@/components/business/pc/product/InsTab.vue';
import inYouWouldLike from '@/components/business/pc/product/InsYouWouldLike.vue';
import YouWouldLike from '@/model/youWouldLike';
import PanelDetail from '@/model/PanelDetail';
import inPanel from '@/components/business/pc/product/InsPanel.vue';
import inPreview from '@/components/business/pc/product/InsPreview.vue';
import inProductUpOrDown from '@/components/business/pc/product/InsProductUpOrDown.vue';
import ProductAttr from '@/model/ProductAttr';
import Tab from '@/model/Tab';
import inComments from '@/components/business/pc/product/InsComments.vue';
@Component({ components: {
  inPreview,
  inPanel,
  inTab,
  inYouWouldLike,
  inComments,
  inProductUpOrDown
} })
export default class InsProductDetail extends Vue {
  private Slider: YouWouldLike[] = [];
  private Tabs: Tab[] = [new Tab('none')];
  private PanelDetail: PanelDetail = new PanelDetail('', '', '', 0, 0);
  private Src: string = '';
  private ImgList: string[] = [];
  private ExtAttrList: any[] = [];
  private ProductCode:string = '0';
  private Score:number=0;
  get pc () {
    return this.$route.params.id;
  }
  created () {
    try {
      let seoData = require('../../sdk/common/SeoData');
      let keywords = document.createElement('meta');
      keywords.setAttribute('name', 'keywords');
      keywords.setAttribute('content', seoData.seoItem['product' + this.pc].keyword);
      document.head.appendChild(keywords);
      let description = document.createElement('meta');
      description.setAttribute('name', 'description');
      description.setAttribute('content', seoData.seoItem['product' + this.pc].description);
      document.head.appendChild(description);
      document.dispatchEvent(new Event('render-event'));
      document.title = seoData.seoItem['product' + this.pc].title;
      this.$store.dispatch('setProductDetailSku', this.pc);
    } catch (e) {
      console.log('當前頁面無需SEO。');
    }
    this.getProduct();
  }
  getProduct () {
    this.ProductCode = this.$route.params.id ? this.$route.params.id : '0';
    // 获取产品详情数据
    this.$Api.product.GetProduct(this.pc).then((result) => {
      this.$nextTick(() => {
        document.title = result.PanelDetail.Name;
      });
      this.PanelDetail = result.PanelDetail;
      this.ExtAttrList = result.PanelDetail.ExtAttrList;
      this.Score = result.PanelDetail.Score;
      this.ImgList = result.AdditionalImage;
      this.Tabs = result.Tab;
      this.$HiddenLayer();
    });
  }
  @Watch('pc', { deep: true })
  onWatchRoute (o, n) {
    this.$store.dispatch('setProductDetailSku', this.pc);
    this.getProduct();
  }
  gotoSlider () {
    this.$nextTick(() => {
      let target = document.getElementById('tab') as HTMLElement;
      target.scrollIntoView();
    });
  }
}

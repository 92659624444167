








































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Form as ElForm, Input } from 'element-ui';
import sdk from '@/sdk/InstoreSdk';
@Component({
  // components: {}
})
export default class InsRegister extends Vue {
  checkEmail (rule, value, callback) {
    const mailReg = /^(\w)+(\.\w+)*@(\w)+((\.\w{2,3}){1,3})$/;
    if (!value) {
      callback(new Error(this.$t('Register.RegisterEmail') as string));
    }
    setTimeout(() => {
      if (mailReg.test(value)) {
        callback();
      } else {
        callback(new Error(this.$t('Register.RegisterEmail01') as string));
      }
    }, 100);
  }

  validatePassword (rule, value, callback) {
    if (value === '') {
      callback(new Error(this.$t('Register.RegisterPassword') as string));
    } else {
      if (this.regForm.Password !== '') {
        // this.$refs.ruleForm.validateField('UserConfirmPassword');
      }

      callback();
    }
  }

  validatePassword2 (rule, value, callback) {
    if (value === '') {
      callback(new Error(this.$t('Register.RegisterNewPassword') as string));
    } else if (value !== this.regForm.Password) {
      callback(new Error(this.$t('Register.RegisterNewPassword01') as string));
    } else {
      callback();
    }
  }
  private regForm = {
    Email: '',
    Password: '',
    ConfirmPassword: '',
    FirstName: '',
    LastName: '',
    Mobile: '',
    BirthMD: '',
    Gender: '',
    Language: '',
    type: ''

  };
  get rules () {
    return {
      Email: [
        { required: true, validator: this.checkEmail, trigger: 'blur' }
      ],
      Password: [
        { required: true, validator: this.validatePassword, trigger: 'blur' }
      ],
      ConfirmPassword: [
        { required: true, validator: this.validatePassword2, trigger: 'blur' }
      ],
      FirstName: [
        {
          required: true,
          message: this.$t('Register.RegisterFirstName'),
          trigger: 'blur'
        }
        //  { min: 2, max: 5, message: '长度在 2 到 5 个字符', trigger: 'blur' }
      ],
      LastName: [
        {
          required: true,
          message: this.$t('Register.RegisterLastName'),
          trigger: 'blur'
        }
        //  { min: 2, max: 5, message: '长度在 2 到 5 个字符', trigger: 'blur' }
      ],
      type: [
        {
          required: true,
          message: this.$t('Register.RegisterAgree_check'),
          trigger: 'blur'
        }
      ],
      Mobile: [
        {
          required: true,
          message: this.$t('Register.RegisterMobile'),
          trigger: 'blur'
        }
      ]
    };
  }
  private submitForm (formName) {
    let _this = this;

    (this.$refs.regForm as ElForm).validate(valid => {
      if (valid) {
        sdk.api.member.register(this.regForm).then(
          function (response) {
            _this.$store.dispatch('submitForm');
            _this.$message({
              message: '注册成功！',
              type: 'success'
            });

            _this.$router.push('/account/registerSuccess');
          },
          function (response) {
            console.log(response, 'ppp');
            // console.log(response.Message, '111122222222222');
            _this.$message({
              message: response,
              type: 'error'
            });
          }
        );
      } else {
        console.log('error submit!!');
        return false;
      }
    });
  }
}







































































































import { Component, Prop, Vue } from 'vue-property-decorator';
@Component
export default class Result extends Vue {
    result:any={
      Id: '',
      No: '',
      Title: '',
      Amount: 0,
      IsPayed: false
    };
    isShowpay:boolean=false;
    paymentMethod:any= {};
    paymentMethods:any[]=[];
    getSubmitComplete (id) {
      this.$Api.regAndPay.getSubmitComplete(id).then(result => {
        this.result = result.data.ReturnValue;
        if (result.data.ReturnValue.Amount > 0 && !result.data.ReturnValue.IsPayed) {
          this.loadPaymentMethod();
        } else if (this.result.NextFormKey) {
          let NextFormKey = this.result.NextFormKey;
          setTimeout(() => {
            this.$router.push({ path: `/regnpay/form/${NextFormKey}` });
          }, 3000);
        }
      });
    }
    loadPaymentMethod () {
      this.$Api.checkout.getPaymentMethod().then(result => {
        this.paymentMethods = result.Payment;
        if (result.Payment.length > 0) {
          this.paymentMethod = result.Payment[0];
        }
      });
    }
    pay () {
      if (!this.paymentMethod.Id || this.paymentMethod.Id === '') {
        alert('请选择付款方式');
      } else {
        this.$Api.regAndPay.savePayMethod(this.paymentMethod.Id, this.result.Id).then(result => {
          if (result.data.Succeeded) {
            this.$router.push({ path: '/payment/' + this.paymentMethod.Code + '/' + this.result.Id });
          } else {
            alert(result.data.Message);
          }
        });
      }
    }

    printThis () {
      alert('print!');
    }

    mounted () {
      console.log(this.$route.params.id);
      this.getSubmitComplete(this.$route.params.id);
    }
}
























































import { Vue, Prop, Component, Watch } from 'vue-property-decorator';
import inTab from '@/components/business/mobile/product/InsTab.vue';
import inYouWouldLike from '@/components/business/mobile/product/InsYouWouldLike2.vue';
import YouWouldLike from '@/model/youWouldLike';
import PanelDetail from '@/model/PanelDetail';
import inPanel from '@/components/business/mobile/product/InsPanel.vue';
import inPreview from '@/components/business/mobile/product/InsPreview.vue';
import ProductAttr from '@/model/ProductAttr';
import Button from '@/model/Button';
import Tab from '@/model/Tab';
import inAccordion from '@/components/base/mobile/InsAccordion.vue';
import inComments from '@/components/business/mobile/product/InsComments.vue';
import inProductUpOrDown from '@/components/business/mobile/product/InsProductUpOrDown.vue';
@Component({ components: {
  inPreview,
  inPanel,
  inTab,
  inYouWouldLike,
  inAccordion,
  inComments,
  inProductUpOrDown
} })
export default class ProductDetail extends Vue {
  private Slider: YouWouldLike[] = [];
  private Tabs: Tab = new Tab('none');
  private PanelDetail: PanelDetail = new PanelDetail('', '', '', 0, 0);
  private Src: string = '';
  private ImgList: string[] = [];
  private ProductSku:string = '0';
  private IsDetail:boolean = true;
  tabIndex: number = 1;

  toggleTab (index) {
    this.tabIndex = index;
  }

  mounted () {
  }
  created () {
    this.ProductSku = this.$route.params.id ? this.$route.params.id : '0';
    // 获取产品详情数据
    this.$Api.product.GetProduct(this.ProductSku).then((result) => {
      this.PanelDetail = result.PanelDetail;
      this.ImgList = result.AdditionalImage;
      this.Tabs = result.Tab;
      this.$HiddenLayer();
    });
  }
  @Watch('$route', { deep: true })
  onWatchRoute (o, n) {
    this.ProductSku = this.$route.params.id;
    this.$Api.product.GetProduct(this.$route.params.id).then((result) => {
      this.PanelDetail = result.PanelDetail;
      this.ImgList = result.Images;
      this.Tabs = result.Tab;
      this.$HiddenLayer();
    });
  }
  gotoSlider () {
    this.$nextTick(() => {
      let target = document.getElementById('tab') as HTMLElement;
      target.scrollIntoView();
    });
  }
}

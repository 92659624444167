



















import { Vue, Prop, Component, Watch } from 'vue-property-decorator';
import YouWouldLike from '@/model/youWouldLike';
import { Loading } from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
@Component({
  components: {
    InsProductList: () => import('@/components/business/mobile/product/InsProductWindow.vue'),
    InsProductAttrsTree: () => import('@/components/business/mobile/product/InsProductAttrsTree.vue'),
    InsProductCatTree: () => import('@/components/business/mobile/product/InsProductCatTree.vue'),
    advancedSearch: () => import('@/components/business/mobile/product/InsAdvancedSearch.vue'),
    InsBanner: () => import('@/components/base/mobile/InsBanner.vue')
  }
})
export default class InsProductSearch extends Vue {
  proList: YouWouldLike[] = []; // 产品数据
  currentPage: number = 1; // 当前页
  pageSize: number = 6; // 每页显示条目个数
  totalRecord: number = 0;// 总条目数
  private tips:boolean = true;
  private LoadingInstance!: any;

  attrs: object[] = []; // 选中的产品属性数组
  searchCatalogs: object[] = []; // 选中的产品目录数组
  storeSearchKey: string = ''; // 搜索关键词
  searchType: number = 1; // 搜索类型（0 => 叠加，1 => 筛选）

  isAdvanced: boolean = true; // 是否显示高级搜索组件

  // 搜索关键词
  get searchKey () {
    let a = this.$store.state.searchKey;
    if (a === '+' || a === '') {
      return '';
    } else {
      return a;
    }
  }

  // 产品高级搜索
  productSearch () {
    this.currentPage = 1;
    this.tips = true;
    this.$Api.product.search({
      Key: this.searchKey,
      PageInfo: {
        Page: this.currentPage,
        PageSize: this.pageSize
      },
      CatIdS: this.searchCatalogs,
      Attrs: this.attrs,
      Type: this.searchType
    }).then((result) => {
      this.proList = result.YouWouldLike;
      this.totalRecord = result.TotalRecord;
      this.$HiddenLayer();
    });
  }

  advancedChange (Attrs, Catalogs) {
    console.log(Attrs, Catalogs, 'advancedChange');
    this.attrs = Attrs;
    this.searchCatalogs = Catalogs;
    this.productSearch();
  }

  handleCurrentChange (val) {
    this.currentPage = val;
    this.productSearch();
  }

  loading (e) {
    if (this.tips) {
      this.LoadingInstance = Loading.service({
        target: this.$refs.load as any,
        fullscreen: false,
        spinner: 'el-icon-loading'
      });
      setTimeout(() => {
        this.load();
        this.LoadingInstance.close();
      }, 2000);
    }
  }
  load () {
    console.log(this.totalRecord, this.proList.length);
    if (this.totalRecord !== this.proList.length) { this.currentPage++; } else { this.tips = false; }
  }

  mounted () {
    if (!this.isAdvanced) {
      this.productSearch();
    }
  }

  @Watch('searchKey')
  onSearchKeyChange () {
    if (!this.isAdvanced) {
      this.productSearch();
    }
  }
  @Watch('currentPage')
  onCurrentPage () {
    setTimeout(() => {
      this.$Api.product.search({
        Key: this.searchKey,
        PageInfo: {
          Page: this.currentPage,
          PageSize: this.pageSize
        },
        CatIdS: this.searchCatalogs,
        Attrs: this.attrs,
        Type: this.searchType
      }).then((result) => {
        this.proList = this.proList.concat(result.YouWouldLike);
        this.totalRecord = result.TotalRecord;
      });
    }, 50);
  }
}

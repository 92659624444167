










import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import Order from '@/model/order';
@Component
export default class InsCompleteCheckout extends Vue {
    private Order:Order =new Order();
    created () {
      this.$Api.order.getOrder(this.$route.params.id).then((result) => {
        this.Order = result.Order;
        this.$HiddenLayer();
      });
    }
}

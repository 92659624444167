







































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Loading } from 'element-ui';
@Component({
  components: {
    InsBanner: () => import('@/components/base/mobile/InsBanner.vue')
  }
})
export default class InsNotification extends Vue {
  multipleSelection: any = [];
  ShowUnRead: boolean = false;
  currentPage: number = 1;
  pagesize: number = 6;
 checkAll:boolean= false;
  tableHeader: any = {
    background: '#eef1f6',
    color: '#606266'
  };
  allMessage:any[]=[];
  mPager = {
    Condition: {
      MemberId: '',
      QueryBgDtStr: '',
      QueryEdDtStr: '',
      Url: '',
      Sku: '',
      OrderId: undefined,
      IsRead: undefined,
      SendType: undefined
    },
    PageInfo: {
      Page: 1,
      PageSize: 5
    }
  };
MessageQty:string='';
TotalNum:string='';
strId:any=[];
selectChange (id) {
  console.log(id);
}
getMsgQty () {
  let that = this;
  this.$Api.messageApi.getUnreadMsgList(that.mPager).then((result) => {
    that.MessageQty = result.data.ReturnValue.TotalRecord;
  });
}
getAllMessage () {
  let that = this;
  that.mPager.PageInfo.Page = this.currentPage;
  that.mPager.PageInfo.PageSize = this.pagesize;
  this.$loading({
    lock: true,
    text: 'Loading',
    spinner: 'el-icon-loading',
    background: 'rgba(0, 0, 0, 0.7)'
  });
  this.$Api.messageApi.getAllMsgList(that.mPager).then((result) => {
    console.log(result, 'result');
    that.allMessage = result.data.ReturnValue.Data;
    that.TotalNum = result.data.ReturnValue.TotalRecord;
    this.$loading({
      lock: true,
      text: 'Loading',
      spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0.7)'
    }).close();
  });
}
markMsgAsRead (id) {
  let that = this;
  this.strId.push(id);
}
changeStatus () {
  if (this.strId !== '') {
    this.$Api.messageApi.markedMessageAsRead(this.strId).then((result) => {
      if (result) {
        this.$message({
          message: '保存成功',
          type: 'success'
        });
        this.getAllMessage();
        this.getMsgQty();
      }
    });
  }
}

mounted () {
  this.getAllMessage();
  this.getMsgQty();
}
IsReadFilter (row, column) {
  return row.IsRead === false ? '未读' : '已读';
}
filterTag (value, row) {
  return row.IsRead === value;
}
currentChange (currentPage) {
  // 改变当前页
  this.currentPage = currentPage;
  this.getAllMessage();
}
// 点击上一页和下一页的时事件
handleSizeChange (psize) {
  this.pagesize = psize;
}
}

import { render, staticRenderFns } from "./InsCatDetail.vue?vue&type=template&id=016ddd1d&scoped=true&"
import script from "./InsCatDetail.vue?vue&type=script&lang=ts&"
export * from "./InsCatDetail.vue?vue&type=script&lang=ts&"
import style0 from "./InsCatDetail.vue?vue&type=style&index=0&lang=css&"
import style1 from "./InsCatDetail.vue?vue&type=style&index=1&id=016ddd1d&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../../node_modules/_vue-loader@15.8.3@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "016ddd1d",
  null
  
)

export default component.exports









































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Form as ElForm, Input } from 'element-ui';
@Component({
  components: {
    InsBanner: () => import('@/components/base/mobile/InsBanner.vue')
  }
})
export default class InsMyFavorite extends Vue {
  currentPage: number = 1;
  pagesize: number = 12;
  FavoriteLength: any = '';
  favoriteList:any[]=[];
  ShowDetail (Sku) {
    // 新窗口打开路由
    let routeData = this.$router.resolve({
      path: '/ProductsDetail/',
      name: 'ProductsDetail',
      params: { id: Sku }
    });
    window.open(routeData.href, '_blank');
  }
  currentChange (currentPage) {
    // 改变当前页
    this.currentPage = currentPage;
  }
  // 点击上一页和下一页的时事件
  handleSizeChange (psize) {
    this.pagesize = psize;
  }

  getAllFavorite () {
    this.$Api.member.getFavorite().then((result) => {
      this.favoriteList = result;
      this.FavoriteLength = result.length;
    });
  }

  removeFavorite (sku) {
    let _this = this;
    let skustring = sku;
    this.$Api.member.removeFavorite(skustring).then((result) => {
      this.favoriteList.pop();
      _this.$message({
        message: this.$t('MyFavorite.RemoveSuccess') as string,
        type: 'success'
      });
    }).catch((error) => {
      console.log(error);
    });
  }
  created () {
    this.getAllFavorite();
  }
}

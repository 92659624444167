


















import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
@Component({})
export default class InsCmsAricle extends Vue {
  cateList: any[] = [];
  content:any[]=[];
  cateTitle:string='';
  categoryIndex:number=0;
  private ImgList: string[] = [];
  private ispic:boolean=false;
  get id () {
    return this.$route.params.id;
  }
  getCateImg () {
    this.$Api.cms.GetCategoryByDevice({ CatId: 40118, IsMobile: false }).then(result => {
      this.ImgList = result.ReturnValue.ImagePath;
      this.cateTitle = result.ReturnValue.Name;
    });
  }
  getContent () {
    this.$Api.cms.getContentByDevice({ ContentId: this.id, IsMobile: false }).then(result => {
      this.content = result.CMS;
      var newDate = new Date(result.CMS.CreateDate.replace(/-/g, '/'));
      result.CMS.CreateDate = newDate.getFullYear() + '-' + (newDate.getMonth() + 1) + '-' + newDate.getDate();
    });
  }
  created () {
    this.getContent();
    this.getCateImg();
  }
  hidden () {
    this.$HiddenLayer();
  }
  @Watch('$route', { deep: true })
  onIdChange () {
    this.$Api.cms.getContentByDevice({ ContentId: this.id, IsMobile: false }).then(result => {
      this.content = result.CMS;
    });
  }
}

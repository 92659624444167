import { render, staticRenderFns } from "./InsRegAndPay.vue?vue&type=template&id=51cc7398&scoped=true&"
import script from "./InsRegAndPay.vue?vue&type=script&lang=ts&scoped=true&"
export * from "./InsRegAndPay.vue?vue&type=script&lang=ts&scoped=true&"
import style0 from "./InsRegAndPay.vue?vue&type=style&index=0&id=51cc7398&lang=less&scoped=true&"
import style1 from "./InsRegAndPay.vue?vue&type=style&index=1&id=51cc7398&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.8.3@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "51cc7398",
  null
  
)

export default component.exports



















import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
@Component({
})
export default class InsCmsLogin extends Vue {
  NewsNav: string = 'NewsNav';
  CateName: string = '';
  content: any[] = [];
  private ImgList: string[] = [];
  private ispic:boolean=false;
  created () {
    let id = this.$route.params.id as string;
    let that = this;
    this.$Api.cms.getContentByDevice({ ContentId: id, IsMobile: true }).then(result => {
      this.content = result.CMS;
      this.CateName = result.CMS.Name;
      this.ImgList = result.CMS.Cover;
    });
  }
  showMessage () {
    this.$alert(this.$t('Cms.InputError') as string + '<a href="/CMS/content/20307" style="color:#7f8ba5">' + this.$t('Cms.Contact') as string + '</a>', this.$t('Cms.Tips') as string, {
      dangerouslyUseHTMLString: true,
      confirmButtonText: this.$t('Cms.Confirm') as string
    });
  }
  @Watch('$route', { deep: true })
  onIdChange () {
    let id = this.$route.params.id as string;
    this.$Api.cms.getContentByDevice({ ContentId: id, IsMobile: true }).then(result => {
      this.content = result.CMS;
      this.CateName = result.CMS.Name;
    });
  }
}

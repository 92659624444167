import { render, staticRenderFns } from "./InsProductCat.vue?vue&type=template&id=2b817c15&scoped=true&"
import script from "./InsProductCat.vue?vue&type=script&lang=ts&"
export * from "./InsProductCat.vue?vue&type=script&lang=ts&"
import style0 from "./InsProductCat.vue?vue&type=style&index=0&id=2b817c15&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.8.3@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b817c15",
  null
  
)

export default component.exports
























import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
@Component({
})
export default class InsCmsContent extends Vue {
  NewsNav: string = 'NewsNav';
  CateName: string = '';
  content: any[] = [];
  private ImgList: string[] = [];
  private ispic:boolean=false;
  private cateImg:string[] = [];
  cateTitle:string='';
  private cateId:string='';
  get id () {
    return this.$route.params.id as string;
  }
  getContent () {
    this.$Api.cms.getContentByDevice({ ContentId: this.id, IsMobile: true }).then(result => {
      this.content = result.CMS;
      this.CateName = result.CMS.Title;
      this.ImgList = result.CMS.Cover;
      this.cateId = result.CMS._CatId;
    });
  }
  getCateImg () {
    this.$Api.cms.GetCategoryByDevice({ CatId: 40118, IsMobile: true }).then(result => {
      this.cateImg = result.ReturnValue.ImagePath;
      this.cateTitle = result.ReturnValue.Name;
    });
  }
  created () {
    this.getContent();
    this.getCateImg();
  }
  hidden () {
    this.$HiddenLayer();
  }
  @Watch('$route', { deep: true })
  onIdChange () {
    this.getContent();
  }
}























import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
@Component({})
export default class InsCatDetail extends Vue {
  cateList: any[] = [];
  content:any[]=[];
  cateTitle:string='';
  categoryIndex:number=0;
  private ImgList: string[] = [];
  private ispic:boolean=false;
  get id () {
    return this.$route.params.id;
  }
  getCateImg () {
    this.$Api.cms.GetCategoryByDevice({ CatId: this.id, IsMobile: true }).then(result => {
      this.ImgList = result.ReturnValue.ImagePath;
      this.cateTitle = result.ReturnValue.Name;
    });
  }
  getCate () {
    this.$Api.cms.getContentsByCatId(this.id, 1, 12).then(result => {
      this.cateList = result.Data;
      result.Data.forEach(function (i) {
        var newDate = new Date(i.CreateDate.replace(/-/g, '/'));
        i.CreateDate = newDate.getFullYear() + '-' + (newDate.getMonth() + 1) + '-' + newDate.getDate();
      });
    });
  }
  showContent (val) {
    if (val.Url) {
      window.open(val.Url);
    } else {
      this.$router.push('/cms/aricle/' + val.Id);
    }
  }
  created () {
    this.getCate();
    this.getCateImg();
  }
  hidden () {
    this.$HiddenLayer();
  }
  @Watch('$route', { deep: true })
  onIdChange () {
    this.$Api.cms.GetCategoryByDevice({ CatId: this.id, IsMobile: true }).then(result => {
      this.ImgList = result.ReturnValue.ImagePath;
      this.cateTitle = result.ReturnValue.Name;
    });
  }
}












import { Vue, Prop, Component, Watch } from 'vue-property-decorator';
import inProductWindow from '@/components/business/mobile/product/InsProductWindow.vue';
import YouWouldLike from '@/model/youWouldLike';
import { Loading } from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
@Component({ components: { inProductWindow } })
export default class InsProductCat extends Vue {
    private LoadingInstance!: any;
    private tips:boolean = true;
    private allItems: YouWouldLike[] = [];
    @Prop() private readonly pageNum!: number;
    @Prop() private readonly call!: Promise<any>;
    private CurrentPage: number = 1;
    private TotalPage: number = 1;
    private TotalRecord: number = 0;
    get catId () {
      return this.$store.state.catId | this.$route.params.catId as any;
    }
    created () {
      this.call.then(() => {
        this.$Api.product.getCatProduct(
          {
            CatId: this.catId,
            Page: this.CurrentPage,
            PageSize: this.pageNum
          }).then((result) => {
          this.allItems = result.YouWouldLike;
          this.TotalPage = result.TotalPage;
          this.TotalRecord = result.TotalRecord;
        });
      });
    }
    @Watch('catId')
    onStoreSearchKey () {
      this.CurrentPage = 1;
      this.tips = true;
      setTimeout(() => {
        window.scrollTo(0, 0);
        this.$Api.product.getCatProduct(
          {
            CatId: this.catId,
            Page: this.CurrentPage,
            PageSize: this.pageNum
          }).then((result) => {
          this.allItems = result.YouWouldLike;
          this.TotalPage = result.TotalPage;
          this.TotalRecord = result.TotalRecord;
        });
      }, 50);
    }
    loading (e) {
      if (this.tips) {
        this.LoadingInstance = Loading.service({
          target: this.$refs.load as any,
          fullscreen: false,
          spinner: 'el-icon-loading'
        });
        setTimeout(() => {
          this.load();
          this.LoadingInstance.close();
        }, 2000);
      }
    }
    load () {
      if (this.TotalRecord !== this.allItems.length) { this.CurrentPage++; } else { this.tips = false; }
    }
    @Watch('CurrentPage')
    onCurrentPage () {
      setTimeout(() => {
        this.$Api.product.getCatProduct(
          {
            CatId: this.catId,
            Page: this.CurrentPage,
            PageSize: this.pageNum
          }).then((result) => {
          this.allItems = this.allItems.concat(result.YouWouldLike);
          this.TotalPage = result.TotalPage;
          this.TotalRecord = result.TotalRecord;
        });
      }, 50);
    }
}

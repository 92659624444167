






























import { Vue, Prop, Component, Watch } from 'vue-property-decorator';
import Comments from '@/model/Comments';
import inPage from '@/components/base/pc/InsPage.vue';
@Component({ components: { inPage } })
export default class InsComments extends Vue {
    private CurrentPage: number = 1;
    private showItems: Comments[] = [];
    private pageNumber: number = 2;
    private currentImage: string = '';
    private showImage: boolean = false;
    private TotalRecord: number = 0;
    @Prop() ProductSku!: string;
    created () {
      this.$Api.comments.getProductComments({
        Sku: this.ProductSku,
        Page: this.CurrentPage,
        PageSize: this.pageNumber
      }).then((result) => {
        this.showItems = result.Comments;
        this.TotalRecord = result.TotalRecord;
        console.log(result);
      });
    }
    mounted () {
    }
    updated () {
    }
    showCommentsImage (e) {
      let targetE = e.target as HTMLElement;
      if (targetE.nodeName === 'IMG') {
        this.currentImage = targetE.dataset.key as string;
        this.showImage = true;
      }
    }
    agree (e) {
    //   let target = e.target as HTMLElement;
    //   if (target.className === 'agree') {
    //     this.showItems[target.dataset.key as string].agree++;
    //   }
    }
    @Watch('CurrentPage')
    onPageChange (o, n) {
    //   this.showItems = this.items.slice((this.currentPage - 1) * this.pageNumber, this.currentPage * this.pageNumber);
    //   this.$nextTick(() => { 目前做不到高度变化时动画
    //     let outer = this.$refs.outer as HTMLElement;
    //     outer.style.height = 'auto';
    //     outer.style.height = outer.offsetHeight + 'px';
    //   });
      this.$Api.comments.getProductComments({
        Sku: this.ProductSku,
        Page: this.CurrentPage,
        PageSize: this.pageNumber
      }).then((result) => {
        this.showItems = result.Comments;
        this.TotalRecord = result.TotalRecord;
      });
    }
    @Watch('ProductSku')
    onProductSkuChange (o, n) {
      this.$Api.comments.getProductComments({
        Sku: this.ProductSku,
        Page: this.CurrentPage,
        PageSize: this.pageNumber
      }).then((result) => {
        this.showItems = result.Comments;
        this.TotalRecord = result.TotalRecord;
      });
    }
};

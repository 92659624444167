
















import { Vue, Prop, Component, Watch } from 'vue-property-decorator';
@Component
export default class InsDialog extends Vue {
    @Prop() private visible!:boolean;
    touch () {
      this.$emit('input', false);
    }
}

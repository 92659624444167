import { render, staticRenderFns } from "./InsShoppingcartItem.vue?vue&type=template&id=2bed8509&scoped=true&"
import script from "./InsShoppingcartItem.vue?vue&type=script&lang=ts&"
export * from "./InsShoppingcartItem.vue?vue&type=script&lang=ts&"
import style0 from "./InsShoppingcartItem.vue?vue&type=style&index=0&id=2bed8509&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/_vue-loader@15.8.3@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2bed8509",
  null
  
)

export default component.exports






































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import ExpressAndOutlets from '@/model/ExpressAndOutlets';
import ExpressPoint from '@/model/ExpressPoint';
import Address from '@/model/address';
import { MemberResult } from '@/model/memberResult';
import { Country } from '@/model/country';
import { Province } from '@/model/province';
import { Form } from 'element-ui';
class ExpressChargeReq {
  ItemAmount!:number;
  PointId!:string;
  constructor (ItemAmount:number, PointId:string) {
    this.ItemAmount = ItemAmount;
    this.PointId = PointId;
  }
}
@Component
export default class InsFare2 extends Vue {
    private Express:ExpressAndOutlets[] = [];
    private ChosenExpress:ExpressAndOutlets = new ExpressAndOutlets();
    private ChosenExpressPoint:ExpressPoint = new ExpressPoint();
    private SelectedAddress:Address = new Address();
    private Profile:MemberResult = new MemberResult();
    private DialogFormVisible:boolean = false;
    private ItemsAmount:number = 0;
    private addressList:Address[] = [];
    private countryList:Country[] = [];
    private provinceList:Province[] = [];
    private editAddress:Address = new Address();
    private allProvice:any = {};
    private TotalWeight:number = 0;
    editAddr (index) {
      this.editAddress = this.addressList[index];
      // console.log(this.SelectedAddress);
      // Object.keys(this.SelectedAddress).forEach((element) => {
      //   this.SelectedAddress[element] = this.addressList[index][element];
      // });
      // this.SelectedAddress.Provinceo = this.addressList[index].Provinceo;
      // this.SelectedAddress.DeliveryId = this.addressList[index].DeliveryId;
    }
    removeAddr (index) {
      let address = this.addressList.splice(index, 1);
      if (address.length) { this.$Api.address.removeAddress(address[0].DeliveryId); }
    }
    selectAddr (index) {
      this.SelectedAddress = this.addressList[index];
      this.DialogFormVisible = false;
      this.$message(this.$t('Message.SucceedInOperating') as string);
    }
    loadAddress () {
      this.DialogFormVisible = true;
      this.$Api.delivery.getAddressForEx(this.ChosenExpress.Id).then((result) => {
        this.addressList = result.Address;
      });
      this.$Api.checkout.getCountry().then((result) => {
        this.countryList = result.Country;
      });
    }
    save (formName) {
      let form = {
        Province: this.editAddress.Provinceo.Id,
        CountryId: this.editAddress.Country.Id,
        ProvinceName: this.editAddress.Provinceo.Name,
        DeliveryId: this.editAddress.DeliveryId === 0 ? undefined : this.editAddress.DeliveryId,
        Default: this.editAddress.Default,
        IsUsable: true,
        FirstName: this.editAddress.FirstName,
        LastName: this.editAddress.LastName,
        Phone: this.editAddress.Phone,
        Mobile: this.editAddress.Mobile,
        PostalCode: this.editAddress.PostalCode,
        Address: this.editAddress.Address
      };
      (this.$refs[formName] as Form).validate((valid) => {
        if (valid) {
          this.$Api.address.saveAddress(form).then((result) => {
          }).then(() => {
            this.$Api.checkout.getAddress2().then((result) => {
              this.addressList = result.Address;
              this.DialogFormVisible = false;
              this.$message(this.$t('Message.SucceedInOperating') as string);
            });
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    }
    created () {
      this.$store.dispatch('setPickUpExpress', true);
      this.$Api.member.getProfile2().then((result) => {
        this.Profile = result.MemberResult;
        this.$store.dispatch('setMemberInfo', result.MemberResult);
      });
      this.$store.state.shopCart.then((result) => {
        this.TotalWeight = result.ShopCart.TotalWeight;
        this.ItemsAmount = result.ShopCart.ItemsAmount;
      }).then(() => {
        this.$Api.delivery.getExpressAndOutlets().then((result) => {
          this.Express = result.ExpressAndOutlets;
          this.ChosenExpress = result.ExpressAndOutlets.length > 0 ? result.ExpressAndOutlets[0] : new ExpressAndOutlets();
          this.ChosenExpressPoint = this.ChosenExpress.ExpressPointList.length ? this.ChosenExpress.ExpressPointList[0] : new ExpressPoint();
        });
      });
    }
    @Watch('ChosenExpress')
    onChosenExpressChange () {
      if (!this.ChosenExpress.IsExpressPoint) {
        this.$store.dispatch('setPickUpExpress', false);
        this.$store.dispatch('setDeliveryType', 'D');
        this.$Api.delivery.getDefaultAddrForEx(this.ChosenExpress.Id).then((result) => {
          this.SelectedAddress = result.Address;
          this.$store.dispatch('setSelectAddress', this.SelectedAddress);
          // this.$store.dispatch('setExpress', this.ChosenExpress);
        }).then(() => {
          return this.$Api.delivery.getExpressChargeForEx({
            DeliveryAddrId: this.SelectedAddress.DeliveryId,
            TotalWeight: this.TotalWeight,
            ItemAmount: this.ItemsAmount,
            ExpressId: this.ChosenExpress.Id });
        }).then((result) => { this.$store.dispatch('setExpress', result.ExpressAndOutlets[0]); });
      } else {
        this.ChosenExpressPoint = this.ChosenExpress.ExpressPointList.length ? this.ChosenExpress.ExpressPointList[0] : new ExpressPoint();
        this.$store.dispatch('setDeliveryType', 'D');
        this.$store.dispatch('setPickUpExpress', true);
        this.$store.dispatch('setExpress', this.ChosenExpress);
        this.$store.dispatch('setExpressPoint', this.ChosenExpressPoint);
      }
    }
    @Watch('ChosenExpressPoint')
    onChosenExpressPoint () {
      this.$Api.delivery.getExpressPointCharge(new ExpressChargeReq(this.ItemsAmount, this.ChosenExpressPoint.Id)).then(
        (result) => { this.ChosenExpressPoint.DiscountPrice = result; this.$store.dispatch('setPickupAddress', this.ChosenExpressPoint); }
      );
    }
    @Watch('editAddress.Country')
    onCountryChange () {
      this.provinceList = this.allProvice[this.editAddress.Country.Id];
      if (!Array.isArray(this.provinceList)) {
        this.$Api.checkout.getProvince(this.editAddress.Country.Id).then((result) => {
          this.provinceList = result.Province;
          this.allProvice[this.editAddress.Country.Id] = result.Province;
        });
      }
    }
    @Watch('DialogFormVisible')
    onDialogFormVisibleChange () {
      if (this.DialogFormVisible) { document.body.style.overflowY = 'hidden'; } else { document.body.style.overflowY = 'auto'; }
    }
    get addressRules () {
      return {
        FirstName: [
          {
            required: true,
            message: this.$t('MemberInfo.EnterUserName'),
            trigger: 'blur'
          }
        ],
        LastName: [
          {
            required: true,
            message: this.$t('MemberInfo.EnterUserLastName'),
            trigger: 'blur'
          }
        ],
        Phone: [
          {
            required: true,
            message: this.$t('MemberInfo.EnterUserPhone'),
            trigger: 'blur'
          }
        ],
        Mobile: [
          {
            required: true,
            message: this.$t('MemberInfo.EnterUserPhone'),
            trigger: 'blur'
          }
        ],
        Country: [
          {
            required: true,
            message: this.$t('Address.Country'),
            validator: (rule, value, callback) => {
              if (!value.Id) {
                callback(new Error(this.$t('Address.Country') as string));
              }
              callback();
            },
            trigger: ['blur', 'change']
          }
        ],
        Provinceo: [
          {
            required: true,
            message: this.$t('Address.Province'),
            validator: (rule, value, callback) => {
              if (!value.Id) {
                callback(new Error(this.$t('Address.Province') as string));
              }
              callback();
            },
            trigger: ['blur', 'change']
          }
        ],
        Address: [
          {
            required: true,
            message: this.$t('Address.Address'),
            trigger: 'blur'
          }
        ]
      };
    }
    @Watch('SelectedAddress')
    onSelectedAddressChange () {
      this.$Api.delivery.getExpressChargeForEx({
        DeliveryAddrId: this.SelectedAddress.DeliveryId,
        TotalWeight: this.TotalWeight,
        ItemAmount: this.ItemsAmount,
        ExpressId: this.ChosenExpress.Id }).then((result) => { this.$store.dispatch('setExpress', result.ExpressAndOutlets[0]); });
    }
}



















import { Vue, Prop, Component, Watch } from 'vue-property-decorator';
import inPage from '@/components/base/pc/InsPage.vue';
import inProductCat from '@/components/business/pc/product/InsProductCat.vue';
import YouWouldLike from '@/model/youWouldLike';
import inPyramidSelling from '@/components/business/pc/product/InsPyramidSelling.vue';
@Component({
  components: {
    inPage,
    inPyramidSelling,
    inProductCat,
    productMenuTree: () => import('@/components/business/pc/product/InsProductCatTree.vue')
  }
})
export default class InsProduct extends Vue {
//   private Items: YouWouldLike[] = [];
//   private PageNumber:number = Vue.prototype.userAgent === 'mobile' ? 2 : 4;
  created () {
    this.$HiddenLayer();
    this.$store.dispatch('setCatId', this.$route.query.catId);
  }
  @Watch('$route', { deep: true })
  onRouteChange (n, o) {
    this.$store.dispatch('setCatId', this.$route.query.catId);
  }
}

import { render, staticRenderFns } from "./InsRegNPayResult.vue?vue&type=template&id=1aceaf28&scoped=true&"
import script from "./InsRegNPayResult.vue?vue&type=script&lang=ts&scoped=true&"
export * from "./InsRegNPayResult.vue?vue&type=script&lang=ts&scoped=true&"
import style0 from "./InsRegNPayResult.vue?vue&type=style&index=0&id=1aceaf28&lang=less&scoped=true&"
import style1 from "./InsRegNPayResult.vue?vue&type=style&index=1&id=1aceaf28&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.8.3@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1aceaf28",
  null
  
)

export default component.exports
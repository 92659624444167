import { render, staticRenderFns } from "./InsProductCat.vue?vue&type=template&id=29280c9f&scoped=true&"
import script from "./InsProductCat.vue?vue&type=script&lang=ts&"
export * from "./InsProductCat.vue?vue&type=script&lang=ts&"
import style0 from "./InsProductCat.vue?vue&type=style&index=0&id=29280c9f&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/_vue-loader@15.8.3@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "29280c9f",
  null
  
)

export default component.exports
























import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
@Component({
})
export default class InsCmsContent extends Vue {
  NewsNav: string = 'NewsNav';
  CateName: string = '';
  content: any[] = [];
  private cateId:string='';
  private ImgList: string[] = [];
  private cateImg:string[] = [];
  private ispic:boolean=false;
  cateTitle:string='';
  get id () {
    return this.$route.params.id as string;
  }
  created () {
    if ((window as any).__PRERENDER_INJECTED) {
      try {
        let seoData = require('../../../../sdk/common/SeoData');
        let keywords = document.createElement('meta');
        keywords.setAttribute('name', 'keywords');
        keywords.setAttribute('content', seoData.seoItem['cms' + this.id].keyword);
        document.head.appendChild(keywords);
        let description = document.createElement('meta');
        description.setAttribute('name', 'description');
        description.setAttribute('content', seoData.seoItem['cms' + this.id].description);
        document.head.appendChild(description);
        document.title = seoData.seoItem['cms' + this.id].title;
        document.dispatchEvent(new Event('render-event'));
      } catch (e) {
        console.log('當前頁面無需SEO。');
      }
    }
    this.getContent();
    this.getCateImg();
  }
  getCateImg () {
    this.$Api.cms.GetCategoryByDevice({ CatId: 40118, IsMobile: false }).then(result => {
      this.cateImg = result.ReturnValue.ImagePath;
      this.cateTitle = result.ReturnValue.Name;
    });
  }
  getContent () {
    this.$Api.cms.getContentByDevice({ ContentId: this.id, IsMobile: false }).then(result => {
      this.content = result.CMS;
      console.log(result, 'resultresult');
      this.CateName = result.CMS.Title;
      this.ImgList = result.CMS.Cover;
      this.cateId = result.CMS._CatId;
      this.$nextTick(() => {
        if (result.CMS.Title) document.title = result.CMS.Title;
      });
    });
  }
  hidden () {
    this.$HiddenLayer();
  }
  @Watch('$route', { deep: true })
  onIdChange () {
    this.getContent();
    this.getCateImg();
  }
}

import { render, staticRenderFns } from "./InsNewsMain.vue?vue&type=template&id=44ee3aa0&"
import script from "./InsNewsMain.vue?vue&type=script&lang=ts&"
export * from "./InsNewsMain.vue?vue&type=script&lang=ts&"
import style0 from "./InsNewsMain.vue?vue&type=style&index=0&lang=less&"


/* normalize component */
import normalizer from "!../../../../../node_modules/_vue-loader@15.8.3@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports